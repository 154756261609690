import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import { IConfig } from '../interfaces/config.interface';
import { ICompanyAccessList } from '../interfaces/users.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly STORAGE_KEY = 'companyAccessList';
  // companyAccessList: ICompanyAccessList | null = null;
  // stateItem: BehaviorSubject<ICompanyAccessList | null> = new BehaviorSubject(this.companyAccessList);
  // stateItem$: Observable<ICompanyAccessList | null> = this.stateItem.asObservable();
  private stateItem: BehaviorSubject<ICompanyAccessList | null>;
  config: IConfig;

  constructor(private httpClient: HttpClient) {
    this.config = {
      apiBaseUrl: env.apiBaseUrl,
      contentType: env.contentType
    };
    const storedItem = localStorage.getItem(this.STORAGE_KEY);
    this.stateItem = new BehaviorSubject<ICompanyAccessList | null>(storedItem ? JSON.parse(storedItem) : null);
  }

  public getCompanyAccess(): Observable<ICompanyAccessList> {
    console.log('🚀 ~ UserService ~ getCompanyAccess');
    return this.httpClient.get(`${this.config.apiBaseUrl}/user/company-access-list/`).pipe(
      map((response: any) => {
        console.log('🚀 ~ UserService ~ map ~ response:', response);
        const companyAccessList = response as ICompanyAccessList;
        this.setState(companyAccessList);
        return companyAccessList;
      }),
      catchError(this.handleError)
    );
  }

  public updateUserInfo(userInfo: IUserInfoUpdate): Observable<any> {
    console.log('🚀 ~ UserService ~ updateUserInfo ~ userInfo:', userInfo);
    return this.httpClient.patch(`${this.config.apiBaseUrl}/account/update/`, userInfo).pipe(
      map((response: any) => {
        console.log('🚀 ~ UserService ~ map ~ response:', response);

        // Get the current state synchronously
        const companyAccessList: ICompanyAccessList | null = this.stateItem.getValue();

        if (companyAccessList) {
          const companyIndex = companyAccessList.companies.findIndex(c => c.company_id);
          if (companyIndex !== -1) {
            const updatedCompany = {
              ...companyAccessList.companies[companyIndex],
              company_roles: userInfo.company_roles,
              departments: userInfo.departments
            };

            const updatedCompanyAccessList = {
              ...companyAccessList,
              companies: [
                ...companyAccessList.companies.slice(0, companyIndex),
                updatedCompany,
                ...companyAccessList.companies.slice(companyIndex + 1)
              ]
            };

            // Set the updated state
            this.setState(updatedCompanyAccessList);
          }
        }

        return response;
      }),
      catchError(this.handleError)
    );
  }

  public deleteUser(): Observable<any> {
    return this.httpClient.delete(`${this.config.apiBaseUrl}/account/delete/`, { observe: 'response' }).pipe(
      tap((response: any) => {
        if (response.status === 204) {
          console.log('Your account has been deleted!');
        }
      }),
      catchError((error: any) => {
        let errMsg = '';

        if (error instanceof HttpErrorResponse) {
          // Handle HttpErrorResponse specifically
          if (error.status === 401) {
            errMsg = 'Unauthorized: Access is denied due to invalid credentials.';
          } else if (error.status === 403) {
            errMsg =
              error.error?.error ||
              'Account is the owner of a company, must transfer ownership before deleting their account.';
          } else {
            errMsg = 'An unexpected error occurred: ' + error.message;
          }
        } else if (error.error) {
          // Handle custom error object from django
          errMsg = 'An error occurred: ' + error.error;
        } else {
          // Handle other types of errors
          errMsg = 'An unknown error occurred: ' + error.message;
        }

        return throwError(() => new Error(errMsg));
      })
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.log('🚀 ~ UserService ~ handleError ~ error:', error);
    return throwError(() => 'Something went wrong; please try again later.');
  }

  // TODO: multi-org compatible
  // getRole(companyId: number): Observable<string | null> {
  //   return this.stateItem.pipe(
  //     map(companyAccessList => {
  //       if (companyAccessList) {
  //         const company = companyAccessList.companies.find(company => company.company_id === companyId);
  //         return company ? company.role : null;
  //       }
  //       return null;
  //     })
  //   );
  // }
  getRole(): Observable<string | null> {
    return this.stateItem.pipe(
      map(companyAccessList => {
        if (companyAccessList && companyAccessList.companies.length > 0) {
          const firstCompany = companyAccessList.companies[0];
          return firstCompany.role;
        }
        return null;
      })
    );
  }

  // TODO: multi-org compatible
  // hasRole(companyId: number, roles: string[]): Observable<boolean> {
  //   return this.getRole(companyId).pipe(map(role => roles.includes(role || '')));
  // }
  hasRole(roles: string[]): Observable<boolean> {
    return this.getRole().pipe(map(role => roles.includes(role || '')));
  }

  // TODO: multi-org compatible
  // isOwner(companyId: number): Observable<boolean> {
  //   return this.getRole(companyId).pipe(map(role => role === 'owner'));
  // }

  // TODO: multi-org compatible
  // isAdmin(companyId: number): Observable<boolean> {
  //   return this.getRole(companyId).pipe(map(role => role === 'admin'));
  // }

  getFullName(): Observable<string | null> {
    return this.stateItem.pipe(map(companyAccessList => (companyAccessList ? companyAccessList.full_name : null)));
  }

  getEmail(): Observable<string | null> {
    return this.stateItem.pipe(map(companyAccessList => (companyAccessList ? companyAccessList.email : null)));
  }

  setState(item: ICompanyAccessList): void {
    console.log('🚀 ~ UserService ~ setState ~ item:', item);
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(item));
    this.stateItem.next(item);
  }

  getState(): Observable<ICompanyAccessList | null> {
    console.log('🚀 ~ UserService ~ getState');
    return this.stateItem.asObservable();
  }

  removeState() {
    console.log('🚀 ~ UserService ~ removeState');
    localStorage.removeItem(this.STORAGE_KEY);
    this.stateItem.next(null);
  }
}

export interface IUserInfoUpdate {
  company_id: number;
  full_name: string;
  departments: string[];
  company_roles: string[];
}

export interface IUserInfoUpdateResponse {
  id: number;
  full_name: string;
  departments: string[];
  company_roles: string[];
}
